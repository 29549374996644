import {createStore} from 'vuex';
import CryptoJS from 'crypto-js';

// Define a secret key for encryption (ensure this is constant and not user-accessible)
const SECRET_KEY = process.env.VUE_APP_SECRET_KEY; // Replace with a strong key

// Utility functions for encryption and decryption
function encrypt(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
}

function decrypt(encryptedData) {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
        console.error('Failed to decrypt data:', error);
        return null;
    }
}

// Create a new store instance.
const store = createStore({
    state() {
        return {
            alert: {
                show: false,
                message: '',
                type: '',
                duration: 2000
            },
            i18nLocale: 'ro',
            locale: 'ro-RO',
            userId: 0,
            superUser: false,
            clientId: 0,
            locationId: 0,
            userList: [],
            clientsList: {},
            userFullName: '',
            offerVMCInitialLoad: true,
            sidebarLinks: [],
            token: '',
            mainMenu: {},
            simpleOfferLocationId: 0,
            activeSection: '',
            breadcrumbsClientId: sessionStorage.getItem('clientId'),
            vuetifyFieldType: 'solo-inverted',
            vmcPossibleTubes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            currency: {
                'ro-RO': {
                    code: ' lei'
                },
            },
            vmcErrors: [],
            PRICE_LIST_PRODUCTS: [],
            fetchedClientOffers: [],
            userRole: '',
            userPermissions: {},
            floorsDefinitions: [
                {name: 'Demisol', value: 1},
                {name: 'Parter', value: 2},
                {name: 'Mansardă', value: 3},
                {name: 'Nivel 1', value: 4},
                {name: 'Nivel 2', value: 5},
                {name: 'Nivel 3', value: 6},
                {name: 'Nivel 4', value: 7},
                {name: 'Nivel 5', value: 8},
                {name: 'Nivel 6', value: 9},
                {name: 'Nivel 7', value: 10},
                {name: 'Nivel 8', value: 11},
                {name: 'Nivel 9', value: 12},
                {name: 'Nivel 10', value: 13},
                {name: 'Nivel 11', value: 14},
                {name: 'Nivel 12', value: 15},
                {name: 'Nivel 13', value: 16},
                {name: 'Nivel 14', value: 17},
                {name: 'Nivel 15', value: 18},
                {name: 'Nivel 16', value: 19},
            ],
            greenHouseClientsFilters: {
                searchQuery: '',
                selectedUsers: [],
                importedClientFilter: 'all',
                itemsPerPage: 10,
            },
        }
    },
    mutations: {
        setClientsFilter(state, {key, value}) {
            state.greenHouseClientsFilters[key] = value;
        },
        configureAlert(state, {message, type, duration, visible}) {
            state.alert.message = message;
            state.alert.type = type;
            state.alert.duration = duration;
            state.alert.show = visible;
        },
        // Set user role
        setUserRole(state, role) {
            state.userRole = role;
        },
        // Update user permissions based on role
        updatePermissions(state, permissions) {
            // Encrypt permissions and store them in sessionStorage
            const encryptedPermissions = encrypt(permissions);
            sessionStorage.setItem('userPermissions', encryptedPermissions);

            // Keep permissions in the state (optional if you want runtime access)
            state.userPermissions = permissions;
        },
        loadPermissionsFromSession(state) {
            // Load encrypted permissions from sessionStorage
            const encryptedPermissions = sessionStorage.getItem('userPermissions');
            if (encryptedPermissions) {
                const decryptedPermissions = decrypt(encryptedPermissions);
                if (decryptedPermissions) {
                    state.userPermissions = decryptedPermissions;
                }
            }
        },
        increment(state) {
            state.count++
        },
        setActiveSection(state, payload) {
            state.activeSection = payload;
        },
        setClientId(state, clientId) {
            if (clientId) {
                state.breadcrumbsClientId = clientId;
                sessionStorage.setItem('clientId', clientId);
            }
        },
        setFetchedClientOffers(state, offer) {
            if (!state.fetchedClientOffers) {
                state.fetchedClientOffers = [];
            }
            state.fetchedClientOffers.push(offer);
        },
        emptyVmcErrorArray(state) {
            state.vmcErrors = [];
        },
        pushVmcError(state, error) {
            let errorObject = {
                color: error.type,
                title: 'Eroare',
                icon: '$error',
                text: error.text,
            };
            if (error.type === 'warning') {
                errorObject.title = 'Atentionare';
                errorObject.icon = '$warning';
            }

            // Check if the error text already exists in the array
            const errorExists = state.vmcErrors.some(e => e.text === errorObject.text);

            // Only add the new error if it doesn't already exist
            if (!errorExists) {
                state.vmcErrors.push(errorObject);
            }
        },
    },
    actions: {
        setUserPermissions({commit}, {role, permissions}) {
            commit('setUserRole', role);
            commit('updatePermissions', permissions);
        },
        loadPermissions({commit}) {
            commit('loadPermissionsFromSession');
        },
    },
    getters: {
        userPermissions: state => state.userPermissions,
        userRole: state => state.userRole,
    },
})
export default store