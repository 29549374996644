<template>
    <a href="#" id="section1" ref="section1"></a>
    <v-card class="mt-4">
        <v-toolbar>
            <v-toolbar-title @click="showContent = !showContent">
                Creare intermediar
            </v-toolbar-title>
        </v-toolbar>
        <v-container v-show="showContent">
            <v-form>
                <v-text-field
                    density="compact"
                    v-model="newIntermediary.name"
                    label="Nume:"
                    required
                    :error-messages="error"
                ></v-text-field>

                <v-text-field
                    density="compact"
                    v-model="newIntermediary.phone"
                    label="Telefon:"
                ></v-text-field>

                <v-text-field
                    density="compact"
                    v-model="newIntermediary.email"
                    label="Email:"
                ></v-text-field>
                <div class="d-flex justify-content-end">
                    <v-btn
                        color="primary"
                        @click="addIntermediary"
                    >
                        Adaugă intermediar
                    </v-btn>
                </div>
            </v-form>
        </v-container>
    </v-card>

    <a href="#" id="section2" ref="section2"></a>
    <v-card class="my-4">
        <v-toolbar density="compact">
            <v-toolbar-title>
                Listă intermediari
            </v-toolbar-title>
            <v-text-field
                v-model="search"
                label="Caută intermediar:"
                placeholder="Introdu numele intermediarului"
                hide-details
                single-line
                solo
            ></v-text-field>
        </v-toolbar>
        <v-data-table
            v-show="filteredIntermediaries.length > 0"
            :headers="headers"
            :items="filteredIntermediaries"
            item-key="id"
            :search="search"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="openEditIntermediary(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="openDelete(item)">
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog"
            width="800px"
        >
            <v-card>
                <v-card-title>
                    Editează intermediarul
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.selectable.name"
                                    label="Nume"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.selectable.phone"
                                    label="Telefon"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="12">
                                <v-text-field
                                    density="compact"
                                    :variant="this.$store.state.vuetifyFieldType"
                                    clearable
                                    v-model="selectedIntermediary.selectable.email"
                                    label="E-mail"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="closeEditIntermediary"
                    >
                        Anulează
                    </v-btn>
                    <v-btn
                        color="primary"
                        variant="text"
                        @click="updateIntermediary"
                    >
                        Salvează
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" width="auto">
            <v-card>
                <v-card-title class="text-h5">Ești sigur că vrei să ștergi intermediarul?</v-card-title>
                <v-spacer></v-spacer>
                <v-container class="d-flex justify-content-end">
                    <v-btn
                        color="primary"
                        variant="text"
                        style="color: white;"
                        @click="closeDelete">Nu
                    </v-btn>
                    <v-btn
                        color="danger"
                        variant="text"
                        style="color: white;"
                        @click="deleteIntermediary">Da
                    </v-btn>
                </v-container>
                <v-spacer></v-spacer>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {VDataTable} from "vuetify/lib/labs/VDataTable";
import {fetchData} from "@/utils/utils";

export default {
    name: "IntermediateList",
    components: {
        VDataTable,
    },
    data() {
        return {
            intermediaries: JSON.parse(sessionStorage.getItem("intermediaries")) || [],
            showContent: true,
            newIntermediary: {
                name: "",
                phone: "",
                email: ""
            },
            search: "",
            sortKey: "name",
            sortOrder: "asc",
            editingIntermediaryId: null,
            error: '',
            sidebarLinks: [
                {
                    name: 'Creare intermediar',
                    link: '#section1',
                    id: 'section1'
                },
                {
                    name: 'Listă intermediari',
                    link: '#section2',
                    id: 'section2'
                },
            ],
            headers: [
                {title: 'Nume', key: 'name'},
                {title: 'Telefon', key: 'phone'},
                {title: 'E-mail', key: 'email'},
                {title: 'Acțiuni', key: 'actions'},
            ],
            dialog: false,
            dialogDelete: false,
            selectedIntermediary: null,
        };
    },
    computed: {
        filteredIntermediaries() {
            return this.intermediaries
                .filter((intermediary) =>
                    intermediary.name.toLowerCase().includes(this.search.toLowerCase())
                )
                .sort((a, b) => {
                    let order = this.sortOrder === "asc" ? 1 : -1;
                    if (this.sortKey === "name") {
                        return order * a.name.localeCompare(b.name, undefined, {sensitivity: "base"});
                    } else {
                        let phoneA = parseInt(a.phone.replace(/[^\d]/g, ""));
                        let phoneB = parseInt(b.phone.replace(/[^\d]/g, ""));
                        return order * (phoneA - phoneB);
                    }
                });
        },
    },
    mounted() {
        this.$store.state.sidebarLinks = this.sidebarLinks;
    },
    methods: {
        openDelete(intermediary) {
            this.selectedIntermediary = intermediary
            this.dialogDelete = true;
        },
        closeDelete() {
            this.dialogDelete = false;
        },
        openEditIntermediary(item) {
            this.selectedIntermediary = JSON.parse(JSON.stringify(item));
            this.dialog = true;
        },
        closeEditIntermediary() {
            this.dialog = false;
        },
        async addIntermediary() {
            if (this.newIntermediary.name.trim() === "") {
                this.error = "Numele intermediarului nu poate fi gol.";
            } else {
                this.error = '';
                let agentId = sessionStorage.getItem('userId')
                const intermediate = {
                    name: this.newIntermediary.name,
                    phone: this.newIntermediary.phone,
                    email: this.newIntermediary.email,
                    agent_id: parseInt(agentId)
                };

                try {
                    const response = await apiClient.post('/api/create_intermediary/', intermediate);
                    const savedIntermediate = response.data;
                    this.intermediaries.push(savedIntermediate);
                    sessionStorage.setItem("intermediaries", JSON.stringify(this.intermediaries));

                    // Clear the input fields
                    this.newIntermediary.name = "";
                    this.newIntermediary.phone = "";
                    this.newIntermediary.email = "";
                    await fetchData('intermediaries', this)
                } catch (error) {
                    console.error("Error adding intermediate:", error.response.data);
                }
            }
        },
        async deleteIntermediary() {
            try {
                let intermediary = this.selectedIntermediary
                await apiClient.delete(`/api/delete_intermediary/${intermediary.selectable.id}/`);
                this.intermediaries = this.intermediaries.filter(intermediate => intermediate.id !== intermediary.selectable.id);
                await fetchData('intermediaries', this)
                sessionStorage.setItem("intermediaries", JSON.stringify(this.intermediaries));
                this.dialogDelete = false;
            } catch (error) {
                console.error("Error deleting intermediate:", error);
            }
        },
        async updateIntermediary() {
            try {
                let intermediary = this.selectedIntermediary;
                await apiClient.put(`/api/update_intermediary/${intermediary.selectable.id}/`, intermediary);
                // Add a success notification if needed
                await fetchData('intermediaries', this)
                this.intermediaries = JSON.parse(sessionStorage.getItem("intermediaries"));
                this.closeEditIntermediary();
                this.editingIntermediaryId = null;
            } catch (error) {
                console.error("Error updating intermediate:", error);
                // Add an error notification if needed
            }
        },
    }
};
</script>

<style scoped>
/* Add your styles here */
</style>
