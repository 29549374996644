<template>
    <v-card v-if="!loading" class="mt-3">
        <v-toolbar>
            <v-toolbar-title>
                <v-btn
                    color="secondary"
                    icon
                    @click="cancel"
                >
                    <v-icon>
                        mdi-arrow-left
                    </v-icon>
                </v-btn>
                {{ isEditing ? 'Editare client' : 'Adăugare client' }}
            </v-toolbar-title>

            <!-- Toolbar items for larger screens -->
            <v-toolbar-items v-if="$vuetify.display.mdAndUp">
                <v-switch
                    v-if="userPermissions.rePowerManagement.canViewRePowerConfirmedStatus"
                    class="mt-2 mr-10"
                    v-model="confirmStatus"
                    label="Confirmat"
                    color="success"
                    variant="outlined"
                    inset
                    density="compact"
                    hide-details
                ></v-switch>
                <v-switch
                    class="mt-2 mr-4"
                    v-model="client.was_contacted"
                    label="Contactat"
                    color="success"
                    variant="outlined"
                    inset
                    density="compact"
                    :readonly="isReadonly"
                    hide-details
                ></v-switch>
                <v-btn :disabled="isReadonly" :color="getVerificationChipColor()" @click="showVerificationModal = true">
                    {{ getVerificationTrueCount() === 10 ? 'Verificat' : 'Verifică' }}
                </v-btn>
            </v-toolbar-items>
            <v-toolbar-items v-else>
                <v-btn size="large" :disabled="isReadonly">
                    <v-icon>mdi-dots-vertical</v-icon>
                    <v-menu
                        activator="parent"
                        :close-on-content-click="false"
                        transition="scale-transition"
                    >
                        <v-list>
                            <v-list-item v-if="userPermissions.rePowerManagement.canViewRePowerConfirmedStatus">
                                <v-list-item-title>
                                    <v-switch
                                        v-model="confirmStatus"
                                        label="Confirmat"
                                        color="success"
                                        variant="outlined"
                                        inset
                                        density="compact"
                                        hide-details
                                    ></v-switch>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-switch
                                        v-model="client.was_contacted"
                                        label="Contactat"
                                        color="success"
                                        variant="outlined"
                                        inset
                                        density="compact"
                                        hide-details
                                    ></v-switch>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title>
                                    <v-btn :disabled="isReadonly" :color="getVerificationChipColor()"
                                           @click="showVerificationModal = true">
                                        {{ getVerificationTrueCount() === 10 ? 'Verificat' : 'Verifică' }}
                                    </v-btn>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form ref="clientForm" v-model="valid" lazy-validation>
                <v-row>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <!-- Contract Number -->
                        <v-text-field
                            label="Nr. contract"
                            variant="outlined"
                            density="compact"
                            v-model="client.contract_number"
                            :disabled="!userPermissions.rePowerManagement.canEditRePowerContractNumber"
                            hide-details
                        />
                    </v-col>
                    <v-col v-if="userPermissions.rePowerManagement.canEditRePowerClientAgent" cols="12" sm="12" md="6"
                           lg="3">
                        <v-autocomplete
                            v-model="client.user"
                            :items="myUsers"
                            label="Agent"
                            variant="outlined"
                            density="compact"
                            item-title="full_name"
                            item-value="id"
                            hide-details
                            clearable
                            @update:modelValue="onUserChange"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <!-- First Name -->
                        <v-text-field
                            label="Prenume"
                            variant="outlined"
                            density="compact"
                            v-model="client.first_name"
                            :rules="[rules.required]"
                            :hide-details="isFirstNameValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <!-- Last Name -->
                        <v-text-field
                            label="Nume"
                            variant="outlined"
                            density="compact"
                            v-model="client.last_name"
                            :rules="[rules.required]"
                            :hide-details="isLastNameValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <!-- CNP -->
                        <v-text-field
                            label="CNP"
                            variant="outlined"
                            density="compact"
                            v-model="client.cnp"
                            :rules="[rules.required, rules.cnp]"
                            :hide-details="isCnpValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2">
                        <!-- Region -->
                        <v-autocomplete
                            clearable
                            label="Județ"
                            variant="outlined"
                            density="compact"
                            :items="regions"
                            v-model="client.region"
                            item-title="name"
                            item-value="id"
                            :rules="[rules.required]"
                            :hide-details="isRegionValid"
                            @update:modelValue="onRegionChange"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <!-- City -->
                        <v-combobox
                            clearable
                            label="Localitate"
                            variant="outlined"
                            density="compact"
                            :items="filteredCities"
                            v-model="client.city"
                            item-title="name"
                            item-value="id"
                            :rules="[rules.required]"
                            :hide-details="isCityValid"
                            @update:modelValue="handleCityChange"
                            allow-new
                            new-item-text="Adaugă noua localitate"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3">
                        <!-- Phone -->
                        <v-text-field
                            label="Email"
                            variant="outlined"
                            density="compact"
                            v-model="client.email"
                            :rules="[rules.email]"
                            :hide-details="isEmailValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <!-- Phone -->
                        <v-text-field
                            label="Telefon"
                            variant="outlined"
                            density="compact"
                            v-model="client.phone"
                            :rules="[rules.phone]"
                            :hide-details="isPhoneValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2">
                        <!-- Street -->
                        <v-text-field
                            label="Strada"
                            variant="outlined"
                            density="compact"
                            v-model="client.street"
                            :rules="[rules.required]"
                            :hide-details="isStreetValid"
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <!-- Number -->
                        <v-text-field
                            label="Nr."
                            variant="outlined"
                            density="compact"
                            v-model="client.number"
                            hide-details
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <!-- Apartment -->
                        <v-text-field
                            label="Ap, Sc Bl, etc"
                            variant="outlined"
                            density="compact"
                            v-model="client.apartment"
                            hide-details
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <!-- Postal Code -->
                        <v-text-field
                            label="Cod Postal"
                            variant="outlined"
                            density="compact"
                            v-model="client.postal_code"
                            hide-details
                            :readonly="isReadonly"
                        />
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <v-text-field
                            v-model="client.panel_number"
                            label="Nr. panouri"
                            variant="outlined"
                            density="compact"
                            type="text"
                            hide-details
                            :readonly="isReadonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <v-text-field
                            v-model="client.panel_power"
                            label="Putere panou"
                            variant="outlined"
                            density="compact"
                            suffix="W"
                            type="text"
                            :rules="[rules.panelPowerMin]"
                            :hide-details="isPanelPowerValid"
                            :readonly="isReadonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <v-text-field
                            v-model="totalInstalledPower"
                            label="Total putere instalata"
                            variant="outlined"
                            density="compact"
                            suffix="kW"
                            readonly
                            :focused="true"
                            :rules="[rules.totalInstalledPowerMin]"
                            :hide-details="isTotalPowerValid"
                            :color="totalInstalledPower >= 3 ? 'success' : 'danger'"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="1">
                        <v-text-field
                            v-model="client.inverter_power"
                            label="Putere invertor"
                            variant="outlined"
                            suffix="KW"
                            density="compact"
                            type="text"
                            hide-details
                            :readonly="isReadonly"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="2" lg="2">
                        <v-combobox
                            v-model="client.inverter"
                            :items="inverterOptions"
                            label="Invertor"
                            variant="outlined"
                            :dirty="false"
                            density="compact"
                            :rules="[rules.required]"
                            :hide-details="isInverterValid"
                            :readonly="isReadonly"
                        ></v-combobox>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <v-select
                            v-model="client.inverter_type"
                            :items="inverterTypeOptions"
                            label="Tip invertor"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :readonly="isReadonly"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <v-select
                            v-model="client.existing_connection_type"
                            :items="['Monofazic', 'Trifazic']"
                            label="Branșament"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :readonly="isReadonly"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="4">
                        <v-textarea
                            v-model="client.notes"
                            label="Note"
                            variant="outlined"
                            density="compact"
                            rows="1"
                            auto-grow
                            hide-details
                            :readonly="isReadonly"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="2">
                        <v-checkbox
                            v-model="client.to_be_rectified"
                            label="De rectificat"
                            variant="outlined"
                            density="compact"
                            hide-details
                            :disabled="isToBeRectifiedDisabled"
                        ></v-checkbox>
                    </v-col>
                    <v-col v-if="client.to_be_rectified" cols="12" sm="12" md="6" lg="4">
                        <v-textarea
                            v-model="client.rectification_notes"
                            label="Note pentru rectificare"
                            variant="outlined"
                            density="compact"
                            rows="1"
                            auto-grow
                            :readonly="!userPermissions.rePowerManagement.canEditRePowerClientRectificationNotes"
                            hide-details
                            :disabled="isReadonly"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="4" lg="3" class="d-flex flex-row align-center flex-wrap">
                        <v-label class="mr-2">
                            Dată C.F.
                        </v-label>
                        <vue-date-picker
                            :time-picker="false"
                            :enable-time-picker="false"
                            v-model="client.cf_expiration_date"
                            locale="ro"
                            :format="formatDate"
                            cancel-text="Anulează"
                            select-text="Selectează"
                            style="width: 200px"
                        ></vue-date-picker>
                    </v-col>
                </v-row>
                <v-row v-show="false">
                    <v-col cols="12" sm="12" md="10" lg="10">
                        <v-file-input
                            label="Carte de identitate"
                            ref="identification_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            @change="handleFileInputChange('identification_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col v-if="client.identification_document" cols="3" sm="3"
                           md="1" lg="1" class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.identification_document)"
                            target="_blank"
                            size="small"
                            variant="elevated"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('identification_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="false">
                    <v-col cols="12" sm="12" md="10" lg="10">
                        <v-file-input
                            label="Carte funciară"
                            ref="cf_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            @change="handleFileInputChange('cf_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col v-if="client.cf_document" cols="3" sm="3"
                           md="1" lg="1" class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.cf_document)"
                            target="_blank"
                            size="small"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('cf_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
                <!-- New Mortgage Verification Checkbox -->
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="9">
                        <v-row class="pl-2">
                            <v-checkbox
                                v-model="client.has_mortgage"
                                label="Există ipotecă la sarcini în extrasul de carte funciară"
                                color="success"
                                class="mr-3"
                                density="compact"
                                hide-details
                                :disabled="isReadonly"
                            ></v-checkbox>
                        </v-row>
                        <v-row class="pl-2">
                            <v-checkbox
                                v-if="this.userPermissions.rePowerManagement.canViewConnectionCertificateCheck"
                                hide-details
                                density="compact"
                                v-model="client.has_connection_certificate"
                                label="Clientul are emis un certificat de racordare pentru un sistem instalat de Sistema"
                                color="success"
                                class="mr-3"
                                :disabled="isReadonly"
                            ></v-checkbox>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="3" class="d-flex align-items-start justify-content-end">
                        <v-btn color="info" :disabled="isReadonly">
                            Adaugă document
                            <v-menu activator="parent">
                                <v-list>
                                    <v-list-item
                                        class="cursor-pointer available-add-doc-item"
                                        v-for="(option, index) in availableAddDocumentsOptions"
                                        :key="index"
                                        @click="triggerFileInput(option.value)"
                                    >
                                        <v-list-item-title>
                                            {{ option.title }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="justify-start">
                    <!-- Iterate over document options -->
                    <template v-for="(option, index) in documentOptions" :key="index">
                        <!-- Determine if we should render this document card -->
                        <template v-if="shouldRenderDocument(option)">
                            <!-- Get the files for this document type -->
                            <template v-for="(file, fileIndex) in getDocumentFiles(option.value)"
                                      :key="`${option.value}-${fileIndex}`">
                                <v-col cols="auto">
                                    <!-- Card with file -->
                                    <v-card
                                        style="position: relative;"
                                        class="document-card mb-2 d-flex flex-column justify-content-evenly"
                                        :color="fileNeedsSave(file) ? 'grey lighten-1' : 'success'"
                                        @mouseenter="setHoveredCard(`${option.value}-${fileIndex}`, true)"
                                        @mouseleave="setHoveredCard(`${option.value}-${fileIndex}`, false)"
                                        @click="openDocument(file.document)"
                                    >
                                        <!-- Card title and content -->
                                        <v-card-title class="text-pre-wrap"
                                                      style="font-size: 1rem; line-height: 1.25rem;">
                                            {{ option.title }}
                                            {{ getDocumentFiles(option.value).length > 1 ? fileIndex + 1 : '' }}
                                        </v-card-title>
                                        <v-card-subtitle
                                            v-if="fileNeedsSave(file)">
                                            Necesită salvare
                                        </v-card-subtitle>

                                        <!-- Delete button with tooltip -->
                                        <v-btn
                                            style="position: absolute; top: 0; right: 0;"
                                            class="ml-1"
                                            color="error"
                                            size="small"
                                            variant="plain"
                                            icon
                                            @mouseover="setHoveredCard(`${option.value}-${fileIndex}`, false)"
                                            @mouseleave="setHoveredCard(`${option.value}-${fileIndex}`, true)"
                                            @click.stop="file.isFileField ? deleteDocument(option.value) : deleteDocumentById(file.id, option, file)"
                                            :disabled="isReadonly"
                                        >
                                            <v-icon>mdi-close</v-icon>
                                            <v-tooltip location="top" activator="parent">
                                                Ștergere
                                            </v-tooltip>
                                        </v-btn>

                                        <!-- View button with tooltip -->
                                        <v-tooltip
                                            v-if="!fileNeedsSave(file)"
                                            location="top"
                                            v-model="hoverStates[`${option.value}-${fileIndex}`]"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <v-card-actions
                                                    class="d-flex justify-content-end"
                                                    style="position: absolute; bottom: 5px; right: 10px;"
                                                    v-bind="props"
                                                >
                                                    <v-icon title="Click to view document">
                                                        mdi-eye-outline
                                                    </v-icon>
                                                </v-card-actions>
                                            </template>
                                            Vizualizare
                                        </v-tooltip>
                                    </v-card>
                                </v-col>
                            </template>

                            <!-- If no files, display a placeholder in a separate column -->
                            <v-col v-if="getDocumentFiles(option.value).length === 0" cols="auto">
                                <!-- Card without file -->
                                <v-card
                                    :disabled="isReadonly"
                                    @click="addDocument(option)"
                                    style="position: relative;"
                                    class="document-card mb-2 d-flex flex-column justify-content-evenly"
                                    color="warning"
                                >
                                    <v-card-title class="text-pre-wrap" style="font-size: 1rem; line-height: 1.25rem;">
                                        {{ option.title }}
                                    </v-card-title>
                                    <v-card-actions class="d-flex justify-content-end"
                                                    style="position: absolute; bottom: 5px; right: 10px;">
                                        Fără fișier
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </template>
                    </template>
                </v-row>

                <!-- Conditionally Display New Document Inputs When Mortgage is Checked -->
                <v-row v-show="false">
                    <!-- Bank Contract Document -->
                    <v-col cols="12" sm="12" md="10" lg="10">
                        <v-file-input
                            label="Contract bancă"
                            ref="bank_contract_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            :bg-color="client.bank_contract_document ? 'success' : ''"
                            @change="handleFileInputChange('bank_contract_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col v-if="client.bank_contract_document" cols="3" sm="3" md="1" lg="1"
                           class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.bank_contract_document)"
                            target="_blank"
                            size="small"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('bank_contract_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>

                    <!-- Latest Payment Proof Document -->
                    <v-col cols="12" sm="12" md="10" lg="10">
                        <v-file-input
                            label="Dovada ultimei plăți (nu mai veche de 60 zile)"
                            ref="latest_payment_proof_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            :bg-color="client.latest_payment_proof_document ? 'success' : ''"
                            @change="handleFileInputChange('latest_payment_proof_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col v-if="client.latest_payment_proof_document" cols="3" sm="3" md="1" lg="1"
                           class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.latest_payment_proof_document)"
                            target="_blank"
                            size="small"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('latest_payment_proof_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="false">
                    <v-col cols="12" sm="12" md="10" lg="10">
                        <v-file-input
                            label="Contract"
                            ref="contract_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            @change="handleFileInputChange('contract_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col v-if="client.contract_document" cols="3" sm="3"
                           md="1" lg="1" class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.contract_document)"
                            target="_blank"
                            size="small"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('contract_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="false">
                    <v-col cols="12" sm="12"
                           :md="this.userPermissions.rePowerManagement.canViewConnectionCertificateCheck ? 6 : 10"
                           :lg="this.userPermissions.rePowerManagement.canViewConnectionCertificateCheck ? 5 : 10">
                        <v-file-input
                            label="Certificat de racordare"
                            ref="connection_certificate_document"
                            variant="outlined"
                            density="compact"
                            :multiple="true"
                            accept=".pdf"
                            hide-details
                            :bg-color="client.connection_certificate_document ? 'success' : ''"
                            @change="handleFileInputChange('connection_certificate_document', $event)"
                        ></v-file-input>
                    </v-col>
                    <v-col
                        v-if="client.connection_certificate_document"
                        cols="3"
                        sm="3"
                        md="1"
                        lg="1"
                        class="d-flex align-items-center">
                        <v-btn
                            class="ml-1"
                            color="info"
                            @click="openDocument(client.connection_certificate_document)"
                            target="_blank"
                            size="small"
                        >
                            <v-icon>mdi-eye-outline</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Vizualizează fișierul
                            </v-tooltip>
                        </v-btn>
                        <v-btn
                            class="ml-1"
                            color="error"
                            @click="deleteDocument('connection_certificate_document')"
                            size="small"
                        >
                            <v-icon>mdi-delete</v-icon>
                            <v-tooltip activator="parent" location="top">
                                Șterge fișierul
                            </v-tooltip>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-row justify-content-between gray-background">
            <div>
                <v-btn
                    v-if="$route.params.clientId"
                    color="danger"
                    @click="removeClient"
                >
                    Șterge client
                </v-btn>
            </div>
            <div>
                <v-btn
                    color="secondary"
                    @click="cancel"
                >
                    Anulează
                </v-btn>
                <v-btn
                    color="primary"
                    @click="saveClient"
                    :disabled="!valid || isReadonly"
                >
                    Salvează
                </v-btn>
            </div>
        </v-card-actions>
        <!-- Verification Modal -->
        <v-dialog
            v-model="showVerificationModal"
            max-width="900px"
        >
            <v-card>
                <v-card-title class="headline">
                    Verificare Documente
                </v-card-title>
                <v-card-text>
                    <v-form ref="verificationForm">
                        <v-container>
                            <v-row v-for="(question, index) in verificationQuestions" :key="index">
                                <v-col cols="12">
                                    <v-row align="center">
                                        <v-col cols="10">
                                            <p>{{ index + 1 }}. {{ question.text }}</p>
                                        </v-col>
                                        <v-col cols="2">
                                            <v-switch
                                                v-model="question.answer"
                                                color="success"
                                                :label="question.answer ? 'Da' : 'Nu'"
                                                inset
                                            ></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn @click="selectAllVerificationQuestions()">
                                    Selectează toate
                                </v-btn>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions class="d-flex flex-row justify-content-end">
                    <v-btn
                        color="danger"
                        @click="showVerificationModal = false"
                    >
                        Anulează
                    </v-btn>
                    <v-btn
                        color="success"
                        @click="submitVerification"
                    >
                        Salvează Verificarea
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
    <v-container v-else class="d-flex justify-content-center">
        <v-progress-circular
            indeterminate
            :size="67"
        ></v-progress-circular>
    </v-container>
</template>

<script>
import apiClient from "@/utils/apiClient";
import {showAlertModal} from "@/utils/utils";
import {mapGetters} from "vuex";
import VueDatePicker from "@vuepic/vue-datepicker";

export default {
    name: 'GreenHouseClientForm',
    components: {VueDatePicker},
    data() {
        return {
            client: {
                contract_number: '',
                first_name: '',
                last_name: '',
                cnp: '',
                phone: '',
                email: '',
                region: null,
                city: '',
                street: '',
                number: '',
                apartment: '',
                postal_code: '',
                panel_number: '',
                panel_power: '',
                inverter_power: '',
                inverter: '',
                inverter_type: 'On grid',
                existing_connection_type: 'Monofazic',
                status: 2,
                was_contacted: false,
                notes: '',
                cf_document: '',
                has_mortgage: false,
                bank_contract_document: '',
                latest_payment_proof_document: '',
                identification_document: '',
                contract_document: '',
                has_connection_certificate: false,
                connection_certificate_document: '',
                to_be_rectified: false,
                rectification_notes: '',
                documents: [], // Added documents property default
                cf_expiration_date: new Date().toISOString().substr(0, 10),
            },
            inverterOptions: ["Solax", "Huawei"],
            identificationDocument: [],
            cfDocument: [],
            latestPaymentProofDocument: [],
            bankContractDocument: [],
            contractDocument: [],
            connectionCertificateDocument: [],
            valid: false,
            rules: {
                required: value => !!value || 'Câmp obligatoriu',
                cnp: () => this.validateCnp(),
                phone: () => this.validatePhone(),
                email: () => this.validateEmail(),
                panelPowerMin: value => (value >= 300) || 'Puterea panoului trebuie să fie de cel puțin 300W',
                totalInstalledPowerMin: value => (value >= 3) || 'Puterea totala instalată trebuie sa fie de peste 3000W (3kW)'
            },
            regions: [],
            subregions: [],
            cities: [],
            showVerificationModal: false,
            verificationQuestions: [
                {
                    text: 'Beneficiarul final este persoana fizică cu domiciliul în România și datele de identificare sunt cele din documentul de identitate (BI/CI/Pasaport)? Se verifică: CNP-ul și numele și prenumele.',
                    answer: false,
                },
                {
                    text: 'Extrasul de carte funciară pe numele beneficiarului final este anexat?',
                    answer: false,
                },
                {
                    text: 'Extrasul de carte funciară pe numele beneficiarului final este eliberat cu maxim 60 de zile calendaristice înainte de data depunerii cererii de finanțare?',
                    answer: false,
                },
                {
                    text: 'Datele de identificare menționate în extrasul de carte funciară se corelează cu cele din documentul de identitate (BI/CI) al beneficiarului final? Se verifică: numele și prenumele, adresa de domiciliu.',
                    answer: false,
                },
                {
                    text: 'Conform informațiilor din contractul comercial, clădirea obiect al proiectului este clădire rezidențială unifamilială?',
                    answer: false,
                },
                {
                    text: 'În cazul în care clădirea obiect al proiectului este deținută în coproprietate de mai multe persoane, sunt îndeplinite condițiile: beneficiarul final deține cel puțin 50% din suprafața utilă a clădirii și beneficiarul final și ceilalți proprietari își dau acordul cu privire la implementarea proiectului (conform contractului comercial dintre solicitant și persoana fizică)?',
                    answer: false,
                },
                {
                    text: 'Contractul comercial între persoana fizică, în calitate de beneficiar final, și solicitant este anexat și conține clauzele minime obligatorii?',
                    answer: false,
                },
                {
                    text: 'Datele de identificare ale părților contractante, menționate în contractul comercial, se corelează cu documentele de identificare ale părților?',
                    answer: false,
                },
                {
                    text: 'Adresa clădirii, obiect al proiectului, este corect menționată în cadrul contractului comercial?',
                    answer: false,
                },
                {
                    text: 'Obiectul contractului comercial corespunde cu activitățile eligibile și obligatorii prevăzute în ghidul specific la secțiunea 1.3?',
                    answer: false,
                },
            ],
            myUsers: [],
            inverterTypeOptions: ["On grid", "Hybrid"],
            confirmStatus: false,
            documentOptions: [
                {
                    title: 'Carte de identitate',
                    value: 'identification_document',
                    dataProp: 'identificationDocument',
                    multiple: true,
                    accept: '.pdf',
                },
                {
                    title: 'Carte funciară',
                    value: 'cf_document',
                    dataProp: 'cfDocument',
                    multiple: true,
                    accept: '.pdf',
                },
                {
                    title: 'Contract bancă',
                    value: 'bank_contract_document',
                    dataProp: 'bankContractDocument',
                    multiple: true,
                    accept: '.pdf',
                },
                {
                    title: 'Dovada ultimei plăți (nu mai veche de 60 zile)',
                    value: 'latest_payment_proof_document',
                    dataProp: 'latestPaymentProofDocument',
                    multiple: true,
                    accept: '.pdf',
                },
                {
                    title: 'Contract',
                    value: 'contract_document',
                    dataProp: 'contractDocument',
                    multiple: true,
                    accept: '.pdf',
                },
                {
                    title: 'Certificat de racordare',
                    value: 'connection_certificate_document',
                    dataProp: 'connectionCertificateDocument',
                    multiple: true,
                    accept: '.pdf',
                }
            ],
            loading: false,
            hoverStates: {},
        };
    },
    computed: {
        ...mapGetters(['userPermissions']),
        isReadonly() {
            return this.confirmStatus && !this.userPermissions.rePowerManagement.canViewRePowerConfirmedStatus;
        },
        isToBeRectifiedDisabled() {
            if (this.userPermissions.rePowerManagement.canViewRePowerConfirmedStatus) {
                return false; // Superusers can always edit
            } else {
                if (this.isReadonly) {
                    return true; // Non-superusers cannot edit when form is readonly
                } else {
                    // Form is not readonly
                    if (this.client.to_be_rectified) {
                        return false; // Non-superusers can uncheck it
                    } else {
                        return true; // Non-superusers cannot check it
                    }
                }
            }
        },
        availableAddDocumentsOptions() {
            let addDocumentsOptions = [
                {
                    title: 'Carte de identitate',
                    value: 'identification_document',
                },
                {
                    title: 'Carte funciară',
                    value: 'cf_document',
                },
                ...(this.client.has_mortgage ? [{title: 'Contract bancă', value: 'bank_contract_document'}] : []),
                ...(this.client.has_mortgage ? [{
                    title: 'Dovada ultimei plăți (nu mai veche de 60 zile)',
                    value: 'latest_payment_proof_document'
                }] : []),
                {
                    title: 'Contract',
                    value: 'contract_document',
                },
                {
                    title: 'Certificat de racordare',
                    value: 'connection_certificate_document',
                },
            ]
            return addDocumentsOptions;
        },
        /**
         * Checks if the client's first name field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's first name is valid; otherwise, false.
         */
        isFirstNameValid() {
            return this.rules.required(this.client.first_name) === true;
        },
        /**
         * Checks if the client's last name field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's last name is valid; otherwise, false.
         */
        isLastNameValid() {
            return this.rules.required(this.client.last_name) === true;
        },
        /**
         * Validates the client's CNP (national identification number) based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's CNP is valid; otherwise, false.
         */
        isCnpValid() {
            return this.rules.cnp(this.client.cnp) === true;
        },
        /**
         * Checks if the client's region field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's region is selected; otherwise, false.
         */
        isRegionValid() {
            return this.rules.required(this.client.region) === true;
        },
        /**
         * Checks if the client's city field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's city is selected; otherwise, false.
         */
        isCityValid() {
            return this.rules.required(this.client.city) === true;
        },
        /**
         * Checks if the client's street field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's street field is valid; otherwise, false.
         */
        isStreetValid() {
            return this.rules.required(this.client.street) === true;
        },
        /**
         * Checks if the client's inverter field meets the required rule.
         * @computed
         * @returns {boolean} - True if the client's inverter field is valid; otherwise, false.
         */
        isInverterValid() {
            return this.rules.required(this.client.inverter) === true;
        },
        /**
         * Checks if the component is in editing mode based on the presence of a client ID in the route params.
         * @computed
         * @returns {boolean} - True if editing an existing client; otherwise, false.
         */
        isEditing() {
            return !!this.$route.params.clientId;
        },
        /**
         * Checks if the client's phone number is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's phone number is valid; otherwise, false.
         */
        isPhoneValid() {
            return this.rules.phone() === true;
        },
        /**
         * Checks if the client's email is valid based on custom validation rules.
         * @computed
         * @returns {boolean} - True if the client's email is valid; otherwise, false.
         */
        isEmailValid() {
            return this.rules.email() === true;
        },
        /**
         * Checks if the client's panel power meets the minimum requirement.
         * @computed
         * @returns {boolean} - True if the panel power is above the minimum; otherwise, false.
         */
        isPanelPowerValid() {
            return this.rules.panelPowerMin(this.client.panel_power) === true;
        },
        /**
         * Checks if the total installed power of the client's panels meets the minimum requirement.
         * @computed
         * @returns {boolean} - True if the total installed power is above the minimum; otherwise, false.
         */
        isTotalPowerValid() {
            return this.rules.totalInstalledPowerMin(this.totalInstalledPower) === true;
        },
        /**
         * Filters the list of cities based on the selected region.
         * @computed
         * @returns {Array<Object>} - A filtered list of cities that match the selected region.
         */
        filteredCities() {
            if (!this.client.region) {
                return [];
            }
            return this.cities.filter(city => city.region_id === this.client.region);
        },
        /**
         * Calculates the total installed power of the client's panels in kilowatts (kW).
         * @computed
         * @returns {number} - The total installed power based on the panel's power and quantity.
         */
        totalInstalledPower() {
            const panelPower = parseFloat(this.client.panel_power) || 0;
            const panelNumber = parseFloat(this.client.panel_number) || 0;
            return (panelPower * panelNumber) / 1000;
        },
    },
    async mounted() {
        this.loading = true;

        if (this.userPermissions.rePowerManagement.canEditRePowerClientAgent) {
            this.loadMyUsers();
        }
        await this.loadRegionsAndCities();
        if (this.isEditing) {
            await this.fetchClientData();
        } else {
            this.client.user = JSON.parse(sessionStorage.getItem('userId'));
        }

        this.loading = false;
    },
    methods: {
        formatDate(date) {
            if (!date) return "";
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        /**
         * Deletes the specified client after user confirmation.
         *
         * This method prompts the user for confirmation before deleting the client, sends a DELETE request if confirmed,
         * removes the client from `clientsList`, and displays a success or error message based on the outcome.
         *
         * @async
         */
        async removeClient() {
            const confirmDelete = confirm('Sigur doriți să ștergeți acest client?');
            let clientId = this.$route.params.clientId;
            if (confirmDelete) {
                try {
                    await apiClient.delete(`/api/green-house-clients/${clientId}/`).then(() => {
                        this.$router.go(-1);
                    })
                    showAlertModal(this.$store, 'Clientul a fost șters cu succes.', 'success', 5000);
                } catch (error) {
                    console.error('Error deleting client:', error);
                    showAlertModal(
                        this.$store,
                        'A apărut o eroare la ștergerea clientului.',
                        'danger',
                        12000
                    );
                }
            }
        },
        fileNeedsSave(file) {
            // Check if the file is not saved (i.e., does not have an ID or originates from the front-end)
            return !file.id && (!file.document || typeof file.document === 'object' && 'name' in file.document);
        },
        // Adjusted method to handle file input changes
        handleFileInputChange(documentType, event) {
            const newFiles = Array.from(event?.target?.files || event); // Get selected files
            newFiles.forEach(file => file.newUpload = true);
            const dataProp = this.getDataPropByDocumentType(documentType); // Map frontend prop

            // Initialize if necessary
            if (!this[dataProp]) {
                this[dataProp] = [];
            }

            // Get existing files for this document type
            const existingFiles = this.getDocumentFiles(documentType);

            // Check if adding new files would exceed the limit
            if (existingFiles.length + newFiles.length > 5) {
                showAlertModal(
                    this.$store,
                    `Puteți adăuga un maxim de 5 fișiere pentru document.`,
                    "warning",
                    7000
                );
                return;
            }

            // If no file in the primary field, save the first file there
            if (!this.client[documentType] && newFiles.length > 0) {
                this.client[documentType] = newFiles.shift(); // Assign first file
            }

            // Save additional files to the documents array
            newFiles.forEach(file => {
                this.client.documents.push({
                    id: null, // Indicates a new file
                    document_type: documentType,
                    document: URL.createObjectURL(file), // Temporary preview
                    originalFile: file, // Store original file for upload
                });
            });

            // Append new files to the frontend data prop for rendering
            this[dataProp].push(...newFiles);
        },
        setHoveredCard(cardId, isHovered) {
            // Directly update the hoverStates object
            this.hoverStates[cardId] = isHovered;
        },
        isMortgageDocument(option) {
            return ['bank_contract_document', 'latest_payment_proof_document'].includes(option.value);
        },
        shouldRenderDocument(option) {
            const hasFiles = this.getDocumentFiles(option.value).length > 0;

            if (this.isMortgageDocument(option)) {
                // Render if the document has files or client.has_mortgage is true
                return hasFiles || this.client.has_mortgage;
            } else {
                // For other documents, always render
                return true;
            }
        },
        addDocument(option) {
            this.$nextTick(() => {
                if (this.$refs[option.value]) {
                    this.$refs[option.value].$el.querySelector('input[type="file"]').click();
                }
            });
        },
        getDataPropByDocumentType(documentType) {
            const docOption = this.documentOptions.find(option => option.value === documentType);
            return docOption ? docOption.dataProp : null;
        },
        getDocumentFiles(documentType) {
            let files = [];

            // Include the primary file field from the client object (saved or not)
            if (this.client[documentType]) {
                files.push({
                    id: null,
                    document: this.client[documentType],
                    isFileField: true,
                });
            }

            // Include additional files from the client.documents array
            const additionalFiles = this.client.documents?.filter(doc => doc.document_type === documentType) || [];
            files = files.concat(
                additionalFiles.map(doc => ({
                    id: doc.id || null, // Unsaved files will have no ID
                    document: doc.originalFile || doc.document, // Use originalFile for unsaved files
                    isFileField: false,
                }))
            );

            return files;
        },
        deleteDocumentById(documentId, option, fileToDelete) {
            const confirmDelete = confirm("Sigur doriți să ștergeți acest document?");
            if (!confirmDelete) return;

            const documentType = option.value;

            if (!documentId) {
                // Remove only the specific unsaved file
                this.client.documents = this.client.documents.filter(doc => {
                    return !(
                        doc.document_type === documentType &&
                        doc.originalFile?.name === fileToDelete?.document?.name
                    );
                });
                showAlertModal(this.$store, "Fișierul nesalvat a fost eliminat.", "success", 5000);
                return;
            }

            // Delete saved file from back end
            apiClient
                .delete(`/api/green-house-clients/documents/${documentId}/`)
                .then(() => {
                    showAlertModal(this.$store, "Documentul a fost șters cu succes.", "success", 5000);
                    this.fetchClientData(); // Refresh client data
                })
                .catch((error) => {
                    console.error("Error deleting document:", error);
                    showAlertModal(this.$store, "A apărut o eroare la ștergerea documentului.", "danger", 5000);
                });
        },
        triggerFileInput(documentType) {
            if (this.$refs[documentType]) {
                this.$refs[documentType].click();
            }
        },
        /**
         * Loads the list of users who are agents, storing them in the `myUsers` array.
         * @method
         */
        loadMyUsers() {
            // Retrieve the list of users from session storage
            const users = JSON.parse(sessionStorage.getItem('myUsers')) || [];
            this.myUsers = users;
        },
        /**
         * Handles the selection of a new user for the client, updating the client's associated user ID.
         * @param {number} newUserId - The ID of the newly selected user.
         * @method
         */
        onUserChange(newUserId) {
            this.client.user = newUserId;
        },
        /**
         * Handles changes in the client's city selection, differentiating between selecting an existing city and creating a new one.
         * @param {(Object|string)} value - The selected city object or a custom city name string.
         * @method
         */
        handleCityChange(value) {
            // If the value is an object (existing city)
            if (typeof value === 'object') {
                // Assign the city ID or name based on your backend expectations
                this.client.city = value?.name;
            }
            // If the value is a string (new custom city)
            else if (typeof value === 'string') {
                this.client.city = value;
            }
        },
        /**
         * Selects all verification questions for the client, setting their answers to true.
         * @method
         */
        selectAllVerificationQuestions() {
            this.verificationQuestions.forEach(question => question.answer = true)
        },
        /**
         * Counts the number of verification questions that the client has answered with "true".
         * @returns {number} - The count of true answers in the client's verification questions.
         * @method
         */
        getVerificationTrueCount() {
            if (this.client.verification_answers && Array.isArray(this.client.verification_answers)) {
                return this.client.verification_answers.filter(answer => answer === true).length;
            }
            return 0;
        },
        /**
         * Determines the color of the verification chip based on the number of true answers.
         * @returns {string} - Returns 'error' for no answers, 'success' for all answers, and 'warning' for partial answers.
         * @method
         */
        getVerificationChipColor() {
            const count = this.getVerificationTrueCount();
            if (count === 0) return 'error'; // Red color for 0/10
            if (count === 10) return 'success'; // Green color for 10/10
            return 'warning'; // Yellow color for 1-9/10
        },
        /**
         * Validates the client's CNP (national identification number) based on format and length requirements.
         * @returns {(boolean|string)} - True if the CNP is valid, or an error message string if invalid.
         * @method
         */
        validateCnp() {
            const cnp = this.client.cnp;
            if (!cnp) {
                return 'Introduceți un CNP valid.';
            }
            if (cnp.length !== 13) {
                return 'CNP trebuie să aibă exact 13 caractere.';
            }
            if (!/^[1256]/.test(cnp)) {
                return 'CNP trebuie să înceapă cu 1, 2, 5 sau 6.';
            }
            return true;
        },
        /**
         * Validates the client's phone number based on length and formatting requirements.
         * @returns {(boolean|string)} - True if the phone number is valid, or an error message string if invalid.
         * @method
         */
        validatePhone() {
            if (
                (!/^\d{10}$/.test(this.client.phone)) &&
                (!/^\+\d{11}$/.test(this.client.phone))
            ) {
                return 'Introduceti un număr de telefon valid, format din 10 cifre sau incepand cu + si 11 cifre.';
            }
            return true;
        },
        /**
         * Validates the client's email address format using a regex pattern.
         * @returns {(boolean|string)} - True if the email is valid, or an error message string if invalid.
         * @method
         */
        validateEmail() {
            const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
            if (!pattern.test(this.client.email)) {
                return 'Introduceti o adresă de email validă.';
            }
            return true;
        },
        /**
         * Submits and saves the client's verification answers, closing the verification modal upon success.
         * @method
         */
        submitVerification() {
            // You may want to validate that all questions have been answered
            // For simplicity, we'll proceed to save the answers
            this.client.verification_answers = this.verificationQuestions.map(q => q.answer);
            this.showVerificationModal = false;
            showAlertModal(this.$store, 'Verificarea a fost salvată.', 'success', 5000);
        },
        /**
         * Opens a specified document in a new browser tab.
         * @param {string} documentUrl - The URL of the document to open.
         * @method
         */
        openDocument(documentUrl) {
            if (documentUrl && documentUrl.includes('media')) {
                // Construct the full URL to the document
                const fullUrl = `${process.env.VUE_APP_API_BASE_URL}${documentUrl}`;
                window.open(fullUrl, '_blank');
            }
        },
        /**
         * Deletes a specified document field from the client's data after user confirmation.
         * @param {string} documentField - The field name of the document to delete.
         * @method
         */
        deleteDocument(documentField) {
            const confirmDelete = confirm("Sigur doriți să ștergeți acest document?");
            if (!confirmDelete) return;

            if (this.client[documentField] && typeof this.client[documentField] === 'object') {
                // Remove unsaved file
                this.client[documentField] = null;
                showAlertModal(this.$store, "Fișierul nesalvat a fost eliminat.", "success", 5000);
                return;
            }

            // Delete saved file from back end
            const clientId = this.$route.params.clientId;
            apiClient
                .patch(`/api/green-house-clients/${clientId}/`, {document_type: documentField})
                .then(() => {
                    this.client[documentField] = null;
                    showAlertModal(this.$store, "Documentul a fost șters cu succes.", "success", 5000);
                })
                .catch((error) => {
                    console.error("Error deleting document:", error);
                    showAlertModal(this.$store, "A apărut o eroare la ștergerea documentului.", "danger", 5000);
                });
        },
        /**
         * Loads regions and cities from an external JSON file, processing and sorting them for display.
         * @async
         * @method
         */
        async loadRegionsAndCities() {
            try {
                const response = await fetch("/cities.json");
                const data = await response.json();

                // Process regions and sort them alphabetically
                this.regions = data
                    .filter(item => item.model === "cities_light.region")
                    .map(item => ({
                        id: item.pk,
                        name: item.fields.name,
                        display_name: item.fields.display_name,
                    }))
                    .sort((a, b) => a.name.localeCompare(b.name));

                // Prefixes to remove from city names
                const prefixesToRemove = ["Comuna", "Oras", "Municipiul"];

                // Process cities and remove prefixes
                this.cities = data
                    .filter(item => item.model === "cities_light.subregion")
                    .map(item => {
                        let {name, display_name} = item.fields;

                        // Remove specified prefixes from name and display_name
                        prefixesToRemove.forEach(prefix => {
                            const regex = new RegExp(`^${prefix}\\s+`, 'i');
                            name = name.replace(regex, '');
                            display_name = display_name.replace(regex, '');
                        });

                        return {
                            id: item.pk,
                            name: name,
                            display_name: display_name,
                            region_id: item.fields.region,
                        };
                    })
                    .sort((a, b) => a.name.localeCompare(b.name));
            } catch (error) {
                console.error("Error loading regions and cities:", error);
            }
        },
        /**
         * Resets the client's selected city when the region changes.
         * @method
         */
        onRegionChange() {
            // Reset city when region changes
            this.client.city = null;
        },
        /**
         * Saves the client's information, including form data and file attachments.
         *
         * This method is invoked when saving a new client or updating an existing client.
         * It first validates the form and, if successful, builds a `FormData` object containing the client's details,
         * including both textual data and any attached files. If the user has `canViewRePowerConfirmedStatus` permission and the confirmation
         * switch (`confirmStatus`) is enabled, the client's status is set to "Confirmat". The method conditionally sends
         * a POST or PUT request to the server based on whether the component is in editing mode.
         *
         * @async
         * @method
         * @throws Will show an error modal if the client data fails to save.
         */
        async saveClient() {
            if (await this.$refs.clientForm.validate()) {
                try {
                    if (this.confirmStatus && this.userPermissions.rePowerManagement.canViewRePowerConfirmedStatus) {
                        this.client.status = 5;  // Set to Confirmat
                    } else if (!this.confirmStatus && this.userPermissions.rePowerManagement.canViewRePowerConfirmedStatus) {
                        this.client.status = 2;
                    }

                    // Convert cf_expiration_date from Date to ISO string if it exists
                    if (this.client.cf_expiration_date instanceof Date) {
                        // Convert to YYYY-MM-DD
                        const year = this.client.cf_expiration_date.getFullYear();
                        const month = String(this.client.cf_expiration_date.getMonth() + 1).padStart(2, '0');
                        const day = String(this.client.cf_expiration_date.getDate()).padStart(2, '0');
                        this.client.cf_expiration_date = `${year}-${month}-${day}`;
                    }

                    const formData = new FormData();

                    // Append client fields (excluding file fields)
                    const fileFields = [
                        'identification_document',
                        'cf_document',
                        'bank_contract_document',
                        'latest_payment_proof_document',
                        'contract_document',
                        'connection_certificate_document',
                    ];

                    for (const [key, value] of Object.entries(this.client)) {
                        if (!fileFields.includes(key)) {
                            formData.append(key, value !== null ? value : '');
                        }
                    }

                    // Handle primary document fields
                    fileFields.forEach(field => {
                        const fieldValue = this.client[field];
                        if (fieldValue && fieldValue instanceof File) {
                            formData.append(field, fieldValue); // Append only if it contains a File
                        }
                    });

                    if (this.client.documents) {
                        // Handle additional documents
                        this.client.documents.forEach(doc => {
                            if (doc.originalFile && doc.originalFile instanceof File) {
                                formData.append(`${doc.document_type}_multiple`, doc.originalFile); // Append additional files
                            }
                        });
                    }

                    // Append verification answers
                    const answers = this.verificationQuestions.map(q => q.answer);
                    formData.append('verification_answers', JSON.stringify(answers));

                    // Send data to the server
                    if (this.isEditing) {
                        const clientId = this.$route.params.clientId;
                        await apiClient.put(`/api/green-house-clients/${clientId}/`, formData);
                    } else {
                        await apiClient.post('/api/green-house-clients/', formData);
                    }

                    // Success message and redirect
                    showAlertModal(this.$store, 'Clientul a fost salvat cu succes.', 'success', 5000);
                    this.$router.push({name: 'ClientsPrecontracts'});
                } catch (error) {
                    console.error('Error saving client:', error);
                    showAlertModal(this.$store, 'A apărut o eroare la salvarea clientului.', 'danger', 12000);
                }
            }
        },
        /**
         * Fetches data for an existing client based on the client ID in the route parameters.
         *
         * This method is called when editing a client. It retrieves the client's data from the server
         * using their unique ID and updates the component's `client` object. If the client's status is
         * "Confirmat", it sets `confirmStatus` to true, allowing for UI updates based on this state.
         * Additionally, the method loads the client's verification answers, mapping them to the component's
         * `verificationQuestions` list, ensuring that each question's answer is accurately restored for display.
         *
         * @async
         * @method
         * @throws Will log an error if client data cannot be retrieved.
         */
        async fetchClientData() {
            const clientId = this.$route.params.clientId;
            try {
                const response = await apiClient.get(`/api/green-house-clients/${clientId}/`);
                if (response && response.data) {
                    this.client = response.data;

                    // Convert cf_expiration_date from ISO string to Date object if it exists
                    if (this.client.cf_expiration_date) {
                        this.client.cf_expiration_date = new Date(this.client.cf_expiration_date);
                    }

                    if (this.client.status === 5) {
                        this.confirmStatus = true;
                    }

                    // Load verification answers
                    if (this.client.verification_answers) {
                        this.verificationQuestions.forEach((question, index) => {
                            question.answer = this.client.verification_answers[index];
                        });
                    }
                }
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        },
        /**
         * Cancels the current action, returning the user to the previous page.
         *
         * This method is triggered when the "Cancel" button is clicked, allowing the user to exit
         * without saving any changes. It navigates the user back to the previous page using the
         * `$router.go(-1)` method.
         *
         * @method
         */
        cancel() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped>
.gray-background:hover {
    background: rgba(30, 30, 30, 0.1);
}

.gray-background {
    background: rgba(30, 30, 30, 0.1);
}

.document-card {
    width: 140px; /* Fixed width for all cards */
    height: 199px; /* Fixed height for all cards */
}
</style>